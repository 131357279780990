import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Hero from "../components/shared/Hero";
import ContentBlock from "../components/shared/sub/ContentBlock";
import SEO from '../components/shared/Seo';
import ImpactBlock from "../components/shared/ImpactBlock";

export default function Purpose() {
  const { sanityPurposePage } = useStaticQuery(graphql`
  {
  sanityPurposePage {
    purposeHeroBlock {
      heroText
      heroImage {
        asset {
          fluid {
            srcWebp
            srcSetWebp
            srcSet
            src
          }
          title
        }
      }
    }
    seoBlock {
      pageDescription
      pageKeyWords
      pageTitle
      ogImage {
        asset {
          url
        }
      }
    }
    impactBlockText {
      _rawContent
    }
  }
}
  `);
  
  if (sanityPurposePage == null){
    return null;
  }

  const { impactBlockText, purposeHeroBlock } = sanityPurposePage;
  const { _rawContent } = impactBlockText;

  return (
    <div>
      <SEO seoData={sanityPurposePage?.seoBlock} slug='purpose' />
      <Hero heroText={purposeHeroBlock.heroText} heroImage={purposeHeroBlock.heroImage} blockVisible />
      <ContentBlock
        blocks={_rawContent}
      />
      <ImpactBlock reversed />
    </div>
  );
};
